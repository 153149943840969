import {
  CssSvg,
  JsWorkSvg,
  NextSvg,
  ReactWorkSvg,
  SeoSvg,
  WordpressSvg,
} from "icons/stack";
import React from "react";
import styled from "styled-components";
import { device } from "styles/Breakpoint";

const stacks = [
  {
    id: 31,
    type: "js",
  },
  {
    id: 20,
    type: "react",
  },
  {
    id: 36,
    type: "next",
  },
  {
    id: 5,
    type: "seo",
  },
  {
    id: 4,
    type: "slice",
  },
  {
    id: 6,
    type: "tut",
  },
  { id: 3, type: "wp" },
];

function index({ data }: any) {
  return (
    <StkRow>
      {data.map((d: number) => {
        let tp = stacks[stacks.findIndex((o) => o.id === d)].type;
        let rend = null;
        switch (tp) {
          case "js":
            rend = <JsWorkSvg />;
            break;

          case "react":
            rend = <ReactWorkSvg />;
            break;

          case "next":
            rend = <NextSvg />;
            break;

          case "seo":
            rend = <SeoSvg />;
            break;

          case "slice":
            rend = <CssSvg />;
            break;

          case "wp":
            rend = <WordpressSvg />;
            break;

          default:
            rend = null;
            break;
        }
        return <Stack key={Math.random()}>{rend}</Stack>;
      })}
    </StkRow>
  );
}

export default index;

const StkRow = styled.div`
  display: flex;
  padding: 24px 0 0;
`;

const Stack = styled.div`
  margin-right: 10px;
  svg {
    width: 30px;
    height: 30px;
    @media ${device.tablet} {
      width: 20px;
      height: 20px;
    }
  }
`;
